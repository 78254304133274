module.exports = {
  ENV: 'prod',
  APP_NAME: 'cal',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'MdSonRVC2w87AXdNLKKczGXFdn8yr7n4N2gBQuEh',
  BRAND_KEY: '817fe87f-cda3-4250-9dbf-e1572d504a3b',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'CAL_AGENTS',
}